export const TimestampToNormaltime = (timestamp) => {
    var date = new Date(timestamp)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes() + ':'
    var s = date.getSeconds()
    return Y+M+D+h+m+s
}

export const TimestampToNormaltimeYMD = (timestamp) => {
    var date = new Date(timestamp)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
    var D = date.getDate()
    return Y+M+D
}

export const NormaltimeToTimestamp = (normaltime) => {
    if(typeof(normaltime) === 'string'){
        const newDate = normaltime.replace(/-/g,'/')
        const timestamp = (new Date(newDate)).getTime()
        return timestamp
    }
    else{
        var date = new Date(normaltime)
        var Y = date.getFullYear() + '/'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        const newDate = Y+M+D+h+m+s
        const timestamp = (new Date(newDate)).getTime()
        return timestamp
    }
}

/**
 * 判断值是否有效
 * 无效值[undeined, null, NaN, '']
 */
export function isValidValue(val) {
    return ![undefined, null, NaN, '', 'NaN'].includes(val);
}

/**
 * 格式化数据
 * 非有效值返回 '--'
 */
export function formatValue(val) {
    if (!isValidValue(val)) return '--';
    return val;
}