import React, { Component } from 'react'
import { Layout, Tabs } from 'antd'

import RegisterForm from '../../../components/RegisterForm/RegisterForm'
import TeaRegisterForm from '../../../components/TeaRegisterForm/TeaRegisterForm'
import BasicFooter from '../../../components/BasicFooter/BasicFooter'
import './Register.scss'

const { Footer, Content } = Layout
const { TabPane } = Tabs

class Register extends Component {
    constructor(props){
        super(props)
        this.state={
            tel: '',
            password: '',
            tabKey:'1'
        }
    }

    TabChange = (key)=> {
        this.setState({
            tabKey:key
        })
    }

    render(){
        return (
            <Layout style={{
                minWidth:'350px'
            }}>
                <Content className='login-content'>
                   <RegisterForm
                       history={this.props.history}
                       tabKey={this.state.tabKey}
                   />

                </Content>
                <Footer  >
                    <BasicFooter/>
                </Footer>
            </Layout>
        )
    }
}

export default Register
