import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Layout,
  Menu,
  Icon,
  Avatar,
  Breadcrumb,
  Dropdown,
  MenuProps,
  Modal,
} from "antd";
import { removeItem, getItem, KEYS } from "../../utils/localStorage";
import { KEYS_SESSION, removeItemSession } from "../../utils/sessionStorage";
import axios from "axios";
import { baseURL } from "../../utils/application";
import "./BasicLayoutForTea.css";
import mainpic_zhinan3 from "../../images/mainpic_zhinan3.png";
import bg1 from "../../images/bg1.png";
import logo1 from "../../images/logo1.gif";
import logo2 from "../../images/logo2.gif";

// import mainbg from "../../images/mainbg.png"

const { Header, Sider, Content } = Layout;
const MenuItem = Menu.Item;
const { SubMenu } = Menu;

class BasicLayoutForTea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSchoolNumber: "",

      collapsed: true,
      defaultSelectedKey: "10",
      defaultOpenKey: "sub3",
      userType: "teacher",
      unseenForumMessageCount: 0,
    };
  }

  componentDidMount = () => {
    const user = JSON.parse(getItem(KEYS.KEY_CUR_USERINFO));
    if (user) {
      this.setState({
        userSchoolNumber: user.userSchoolNumber,
        userName: user.userName,
        userType: user.userType,
        userId: user.userId,
      });
    }
    // 获取讨论区未读消息
    axios
      .get(baseURL + `/discussMessageCount`, {
        params: {
          userId: user.userId,
          type: 0,
          seen: 0,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({
          unseenForumMessageCount: response.data.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching:", error);
      });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleLogOut = async () => {
    const res = await axios.get(
      "https://pmath.club:8443/homeworkManager/logout"
    );
    if (res && res.data === "ok") {
      removeItem(KEYS.KEY_CUR_ANSWER);
      removeItem(KEYS.KEY_CUR_USERINFO);
      removeItem(KEYS.KEY_CUR_CLASSWORK);
      removeItem(KEYS.KEY_CUR_CHAPTER);
      removeItemSession(KEYS_SESSION.KEY_STA_STUID);
      removeItemSession(KEYS_SESSION.KEY_STA_COURSEID);
      removeItemSession(KEYS_SESSION.KEY_STA_DATA);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  // function = () =>{
  //   var sidelist = document.getElementById('sidelist');
  //   var li = sidelist.getElementsByTagName('li');
  //   // li[0].className = 'active'
  //   for (var i = 0; i < li.length; i++) {
  //       li[i].onclick = function() {
  //           for (var i = 0; i < li.length; i++) {
  //               li[i].className = '';
  //           }
  //           this.className = 'active';
  //       }
  //   }
  // }

  handleLogoutClick = async () => {
    console.log(111);
    this.setState({
      showModal: true,
    });
    //this.renderModal()
  };
  handleConfirmLogout = async () => {
    // 在这里处理注销逻辑，例如调用API等
    const userId = this.state.userId;
    const res = await axios({
      url: baseURL + "/deleteUser",
      method: "post",
      params: {
        userId: userId,
      },
    });
    if (res && res.status === 200) {
      alert("账号已注销");
      console.log(res.data);
    } else {
      alert("注销账号失败");
    }
    // 注销后隐藏模态对话框
    this.setState({
      showModal: false,
    });
    // 可能需要清除本地存储中的认证信息
    removeItem(KEYS.KEY_CUR_TAB_KEY);
    //localStorage.removeItem('userToken'); // 假设你使用了localStorage存储token
    const { history } = this.props;
    history.push("/");
  };
  // 点击取消时隐藏模态对话框
  handleCancelLogout = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { title, describe, breadcrumb } = this.props;
    const { userSchoolNumber, userName, userType } = this.state;
    const menu = (
      <Menu>
        <MenuItem key="0" onClick={this.handleLogOut.bind(this)}>
          <Link to="/">
            <Icon type="poweroff" />
            &nbsp;&nbsp;
            <span>退 出</span>
          </Link>
        </MenuItem>
        <MenuItem key="1">
          <Link to="#">
            <Icon type="setting" />
            &nbsp;&nbsp;
            <span>设 置</span>
          </Link>
        </MenuItem>
      </Menu>
    );
    return (
      <div>
        <Layout style={{ width: "100%", height: "100vh" }}>
          {/* <Sider 
            trigger={null} 
            collapsible 
            collapsed={this.state.collapsed}
            style={{
              height: "100vh",
              overflow: "auto",
              width: "",
            }}
            >
            <div>
              {
                this.state.collapsed
                ? (
                  <Avatar
                      src='https://pmath.club:8443/data/teacher_head_logo.png'
                      style={{ margin: '16px'}} 
                      size='large'
                  />
                )
                : (
                  <h3 className='logo'>作业管理系统</h3>
                )
              }
            </div>
            <Menu
                  theme="dark"
                  mode="inline"
                > 
                  <SubMenu
                  key="sub1"
                  title={
                    <Link to='#' style={{textAlign:'center'}}>
                      <span style={{textAlign:'center'}}>
                        <Icon type="project" style={{fontSize:'12px'}} theme='filled' />
                        <span>课程管理</span>
                    </span>
                    </Link>
                  }
                >
                  <MenuItem key='1'>
                    <Link to='/mineCourse'>
                      <Icon type="appstore" theme='filled' />
                      <span>我的课程</span>
                    </Link>
                  </MenuItem>
                </SubMenu>
                </Menu>
                <Menu
                  theme="dark"
                  mode="inline"
                >
                <SubMenu
                  key="sub1"
                  title={
                    <Link to='#' style={{textAlign:'center'}}>
                      <span style={{textAlign:'center'}}>
                      <Icon type="user" style={{fontSize:'12px'}} />
                        <span>我的</span>
                    </span>
                    </Link>
                    
                  }
                >
                  <MenuItem
                    key="1"
                  >
                      <span>你好！</span>
                      &nbsp;
                      <span style={{fontStyle:'italic'}}>{userSchoolNumber}</span>
                      &nbsp;
                      <span style={{fontStyle:'italic'}}>{userName}</span> 老师
                  </MenuItem>
                  <MenuItem 
                    key="0"
                    onClick={this.handleLogOut.bind(this)}
                  >
                      <Link to='/'>
                          <Icon type="poweroff" />
                          &nbsp;&nbsp;
                          <span>退 出</span>
                      </Link>
                  </MenuItem>
                  <MenuItem 
                    key="1"
                  >
                      <Link to='#'>
                          <Icon type="setting" />
                          &nbsp;&nbsp;
                          <span>设 置</span>
                      </Link>
                  </MenuItem>
                </SubMenu>
                </Menu>
          </Sider> */}

          {/* <Layout width='90vw'> */}
          <Header
            style={{
              paddingLeft: "0px",
              paddingTop: "0px",
              minWidth: "350px",
              backgroundColor: "#fff",
              height: "80px",
            }}
          >
            <img
              src={logo1}
              width={256}
              height={33}
              style={{ position: "absolute", left: "40px", top: "5px" }}
            />
            <img
              src={logo2}
              width={260}
              height={43}
              style={{ position: "absolute", left: "40px", top: "38px" }}
            />
            {/* <div style={{
              display:'flex',
              backgroundColor:'#feff99',
              boxShadow:'inset 0px -5px 0px #fcc401',
              justifyContent:'center'
            }}> */}
            {/* <div style={{zIndex:9999}}>
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
              </div> */}
            {/* 添加menu */}
            {/* <div className="logo"/> */}
            {
              <Modal
                closable={false}
                visible={this.state.showModal}
                width="45vw"
                height="45vh"
                onOk={() => this.handleConfirmLogout()}
                onCancel={() => this.handleCancelLogout()}
              >
                <div>
                  <h4>确定要注销账号吗？一旦确定无法更改，请慎重考虑。</h4>
                </div>
              </Modal>
            }
            <Menu
              id="mainMenu"
              theme="light"
              mode="horizontal"
              style={{
                display: "-webkit-flex",
                display: "flex",
                WebkitJustifyContent: "space-around",
                justifyContent: "space-around",
                height: "60px",
                position: "relative",
                left: "300px",
                top: "20px",
                width: "70%",
              }}
              activeBarBorderWidth="0px"
              activeBarHeight="0px"
            >
              <SubMenu
                key="sub0"
                title={
                  <Link to="/homePage">
                    <span>首页</span>
                  </Link>
                }
              ></SubMenu>
              <SubMenu
                key="sub1"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="project" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>课程管理</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="1">
                  <Link to="/mineCourse">
                    <Icon type="appstore" theme="filled" />
                    <span>我的课程</span>
                  </Link>
                </MenuItem>
                <MenuItem key="2">
                  <Link to="/addCourse">
                    <Icon type="plus-circle" theme="filled" />
                    <span>创建课程</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub2"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="read" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>作业管理</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="3" onClick={this.handleClick}>
                  <Link to="/allClassworks">
                    <Icon type="book" theme="filled" />
                    <span>作业管理</span>
                  </Link>
                </MenuItem>
                <MenuItem key="4">
                  <Link to="/addTask" onClick={this.handleClick}>
                    <Icon type="plus-square" theme="filled" />
                    <span>作业发布</span>
                  </Link>
                </MenuItem>
                <MenuItem key="5">
                  <Link to="/taskTable" onClick={this.handleClick}>
                    <Icon type="eye" theme="filled" />
                    <span>作业批改</span>
                  </Link>
                </MenuItem>
                <MenuItem key="6">
                  <Link to="/teacherAssistant" onClick={this.handleClick}>
                    <Icon type="database" theme="filled" />
                    <span>教师助手</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub3"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="database" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>测评结果</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="7">
                  <Link to="/chapters/test">
                    <Icon type="file-word" theme="filled" />
                    <span>章节测试</span>
                  </Link>
                </MenuItem>
                <MenuItem key="16">
                  <Link to="/teaChapterhomework">
                    <Icon type="file" theme="filled" />
                    <span>课后习题</span>
                  </Link>
                </MenuItem>
                <MenuItem key="15">
                  <Link to="/homeworkText">
                    <Icon type="file" theme="filled" />
                    <span>自主练习</span>
                  </Link>
                </MenuItem>
                <MenuItem key="17">
                  <Link to="/teaClassTest">
                    <Icon type="file" theme="filled" />
                    <span>课堂小测试</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub4"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="appstore" style={{fontSize:'13px'}} theme='filled' /> */}
                      <span>课程资源</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="8">
                  <Link to="/chapters/source">
                    <Icon type="file-ppt" theme="filled" />
                    <span>课程资源</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub5"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="book" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>统计数据</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="9" onClick={this.handleClick}>
                  <Link to="/statisticalData">
                    <Icon type="read" theme="filled" />
                    <span>学生作业情况统计</span>
                  </Link>
                </MenuItem>
                <MenuItem key='10' >
                  <Link to="/studyAnalysis">
                    <Icon type="file-ppt" theme="filled" />
                    <span>学情分析</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub9"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="book" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>智能组卷</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="9" onClick={this.handleClick}>
                  <Link to="/organizepaper">
                    <Icon type="read" theme="filled" />
                    <span>组织试卷</span>
                  </Link>
                </MenuItem>
                <MenuItem key="10" onClick={this.handleClick}>
                  <Link to="/intelligent">
                    <Icon type="container" theme="filled" />
                    <span>智能组卷</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub10"
                title={
                  <Link to="#">
                    <span>
                      <span>讨论区</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="15">
                  <Link to="/forum">
                    <span>讨论列表</span>
                  </Link>
                </MenuItem>
                <MenuItem key="16">
                  <Link to="/addDiscuss">
                    <span>新建讨论</span>
                  </Link>
                </MenuItem>
                <MenuItem key="17">
                  <Link to="/discussManage">
                    <span>管理页面</span>
                  </Link>
                </MenuItem>
                <MenuItem key="18">
                  <Link to="/discussRank">
                    <span>排行榜</span>
                  </Link>
                </MenuItem>
                <MenuItem key="19">
                  <Link to="/discussMessage">
                    <span>我的消息</span>
                    {this.state.unseenForumMessageCount != 0 && (
                      <img src="newMessage.png" />
                    )}
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                key="sub7"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="profile" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>信息管理</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="13">
                  <Link to="/passwordModify">
                    <Icon type="edit" theme="filled" />
                    <span>修改密码</span>
                  </Link>
                </MenuItem>
                <MenuItem key="13">
                  <div onClick={this.handleLogoutClick}>
                    <Icon type="delete" theme="filled" />
                    <span>注销账号</span>
                  </div>
                </MenuItem>
                {/*<MenuItem key='2'>
                <Link to='#'>
                  <Icon type="mail" theme='filled' />
                  <span>基本信息</span>
                </Link>
              </MenuItem>*/}
              </SubMenu>
              <SubMenu
                key="sub8"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="message" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>用户手册</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="14">
                  <Link to="/home">
                    <Icon type="info-circle" theme="filled" />
                    <span>使用说明</span>
                  </Link>
                </MenuItem>
              </SubMenu><SubMenu
                key="sub6"
                title={
                  <Link to="#">
                    <span>
                      {/* <Icon type="pay-circle" style={{fontSize:'12px'}} theme='filled' /> */}
                      <span>积分系统</span>
                    </span>
                  </Link>
                }
              >
                <MenuItem key="10">
                  <Link to="/exchangeTable">
                    <Icon type="dropbox-circle" theme="filled" />
                    <span>积分兑换</span>
                  </Link>
                </MenuItem>
                <MenuItem key="11">
                  <Link to="/ordersTable">
                    <Icon type="gift" theme="filled" />
                    <span>订单管理</span>
                  </Link>
                </MenuItem>
                <MenuItem key="12">
                  <Link to="/pointsRule">
                    <Icon type="info-circle" theme="filled" />
                    <span>积分说明</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              
            </Menu>
            <div
              width={56}
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                width: "56",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.collapsed ? (
                <div style={{ height: "80px", overflow: "hidden" }}>
                  <Avatar
                    src="https://pmath.club:8443/data/teacher_head_logo.png"
                    style={{
                      margin: "20px",
                      width: "40px",
                      height: "40px",
                    }}
                    // size='large'
                  />
                  <a href="#">
                    <Icon
                      type="logout"
                      style={{
                        fontSize: "25px",
                        margin: "20px 20px 20px 10px",
                        position: "relative",
                        top: "5px",
                      }}
                    />
                  </a>
                  {/* <span style={{color:'#000', textAlign:'center', margin:'auto'}}>{userName} 老师</span> */}
                </div>
              ) : (
                <h3 className="logo">作业管理系统</h3>
              )}
            </div>
            {/* <div 
                style={{
                  paddingRight:'0px'
                }}
              >
                <Dropdown overlay={menu} >
                  <div 
                    className='user'
                    style={{
                      display:'inline',
                      position:'absolute',
                      right:'5rem',
                    }}
                  >
                    <span>你好！</span>
                    &nbsp;
                    <span style={{fontStyle:'italic'}}>{userSchoolNumber}</span>
                    &nbsp;
                    <span style={{fontStyle:'italic'}}>{userName}</span> &nbsp;&nbsp;老师
                  </div>
                </Dropdown>
              </div> */}
            {/* </div> */}
          </Header>
          <Layout
            style={{
              background:
                'url("https://pmath.club:8443/data/img/backgroundImage/images/mainbg.png")',
            }}
          >
            <Sider
              trigger={null}
              collapsible
              // collapsed={this.state.collapsed}
              // theme='light'
              // background='../../images/mainpic_zhinan3.gif'
              style={{
                // height:'100vh',
                overflow: "auto",
                // background:'url("https://pmath.club:8443/data/img/backgroundImage/images/bg1.png")',
                backgroundRepeat: "no-repeat",
                background: "transparent",
                overflow: "hidden",
              }}
              width={300}
            >
              <div style={{ width: "100%", height: "80px" }}></div>
              {this.props.sidelist()}
              {/* <img src={mainpic_zhinan3} width={201} height='100%'/> */}
              {/* <Menu
                  mode="inline"
                  theme='dark'
                  style={{
                    // position:'relative',
                    // top:'-300px',
                    // margin:'20px 20px',
                    backgroundColor:'transparent',
                    width:'160px',
                    // borderRadius:'10px'
                  }}
                  selectedKeys='1'
                >
                  <MenuItem
                    key="1"
                    style={{
                      // borderRadius:'10px',
                      // backgroundColor:'#fcc401',
                      width:'200px',
                      height:'50px',
                      // margin:'10px',
                      color:'#1890ff'
                    }}
                  >
                      <Link to='/mineCourse'>
                        <div style={{
                          transform: 'skewx(-30deg) translatex(-20px)',
                          background:'#005ca2',
                          width:'200px',
                          height:'50px',
                          position:'absolute',
                          top:'0'
                        }}></div>
                        <Icon type="appstore" theme='filled' />
                        &nbsp;&nbsp;
                        <span>我的课程</span>
                      </Link>
                  </MenuItem>
                  <MenuItem 
                    key="1"
                    style={{
                      borderRadius:'10px',
                      // backgroundColor:'#fcc401',
                      width:'140px',
                      margin:'10px',
                      color:'#fff'
                    }}
                  >
                      <Link to='/addCourse'>
                        <Icon type="plus-circle" theme='filled' />
                        &nbsp;&nbsp;
                        <span>创建课程</span>
                      </Link>
                  </MenuItem> 
                </Menu> */}

              {/* <Menu

                  theme="dark"
                  mode="inline"
                > 
                  <SubMenu
                  key="sub1"
                  title={
                    <Link to='#' style={{textAlign:'center'}}>
                      <span style={{textAlign:'center'}}>
                        <Icon type="project" style={{fontSize:'12px'}} theme='filled' />
                        <span>课程管理</span>
                    </span>
                    </Link>
                  }
                >
                  <MenuItem key='1'>
                    <Link to='/mineCourse'>
                      <Icon type="appstore" theme='filled' />
                      <span>我的课程</span>
                    </Link>
                  </MenuItem>
                </SubMenu>
                </Menu> */}
              {/* <Menu
                  theme="dark"
                  mode="inline"
                  style={{
                    position:'relative',
                    top:'-300px',
                  }}
                >
                <SubMenu
                  key="sub1"
                  title={
                    <Link to='#' style={{textAlign:'center'}}>
                      <span style={{textAlign:'center'}}>
                      <Icon type="user" style={{fontSize:'12px'}} />
                        <span>我的</span>
                    </span>
                    </Link>
                  }
                >
                  <MenuItem
                    key="1"
                  >
                      <span>你好！</span>
                      &nbsp; 
                      <span style={{fontStyle:'italic'}}>{userSchoolNumber}</span>
                      &nbsp; 
                      <span style={{fontStyle:'italic'}}>{userName}</span> 老师
                  </MenuItem>
                  <MenuItem 
                    key="0"
                    onClick={this.handleLogOut.bind(this)}
                  >
                      <Link to='/'>
                          <Icon type="poweroff" />
                          &nbsp;&nbsp;
                          <span>退 出</span>
                      </Link>
                  </MenuItem> 
                  <MenuItem 
                    key="1"
                  >
                      <Link to='#'>
                          <Icon type="setting" />
                          &nbsp;&nbsp;
                          <span>设 置</span>
                      </Link>
                  </MenuItem>
                </SubMenu>
                </Menu> */}
            </Sider>
            <Layout
              className="layout"
              style={{
                minWidth: "350px",
                background: "transparent",
                padding: "0 24px 24px",
              }}
            >
              {/* <Header 
                        style={{
                          lineHeight: '32px',
                          height:'75px',
                          paddingLeft:0,
                          backgroundColor:'#ffffff',
                          boxShadow:'inset 0px -2px 1px 0px #BEBEBE'
                        }} 
                    > */}
              <Breadcrumb style={{ margin: "30px 0" }}>
                <Breadcrumb.Item style={{ fontSize: "14px" }}>
                  首页
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{ fontSize: "14px" }}>
                  {breadcrumb}
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* </Header> */}
              <Content
                style={{
                  minWidth: "350px",
                  overflow: "auto",
                  // padding:'3vh 2vw',
                  backgroundColor: "#ffffff",
                  padding: "40px 60px",
                  margin: "0",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "22px",
                      // paddingLeft:'2vw',
                      paddingBottom: "10px",
                    }}
                  >
                    {title}
                  </div>
                  <div
                    style={{
                      background: "#f47c40",
                      width: "60px",
                      height: "3px",
                      marginBottom: "20px",
                    }}
                  ></div>
                  {/* <div 
                          style={{
                            display:'inline',
                            color:'#bbb'
                            // paddingLeft:'2vw',
                            // paddingBottom:'1vh'
                          }}
                        >
                          {describe}
                        </div> */}
                </div>
                {this.props.children}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default withRouter(BasicLayoutForTea);
