// export const baseURL = "http://47.108.115.111:8080/homeworkManager"
// export const baseURL = "https://pmath.club:8443/homeworkManager"
// 本地测试
// export const baseURL = "http://localhost:8080";
// export const baseIP = "http://localhost:8080";

// 新离散数学服务器https端口于2024.5.9添加  从此上面的作废，改为更安全的https连接
export const baseURL = "https://pmath.club:8443/homeworkManager"
export const baseIP= 'https://pmath.club:8443';

// 新离散数学服务器于2022.6.27添加
// export const baseURL = "https://pmath.club:8443/homeworkManager"
// export const baseIP= 'https://pmath.club:8443';

// export const baseURL = "https://localhost:443"
// export const baseIP= 'https://localhost:443';