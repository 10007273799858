import React from 'react'
import MyRoute from './router/index'

import './App.css';
// import { MathJax, MathJaxContext } from "better-react-mathjax";
function App() {
  return (
      <div style={{
        minWidth:'350px'
      }}>
        <MyRoute />
      </div>
  );
}

export default App;
