import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Radio,
  message,
} from "antd";
import axios from "axios";
import { getItem, KEYS, setItem } from '../../utils/localStorage'
import { baseURL } from "../../utils/application";
const { Option } = Select;

class RegistrationForm2 extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    value: null,
    number: 0,
  };

  handleSubmitTea = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name, password, gender, number } = values;

        //userSchoolNumber=null, userName=null, userType=null, userAuth=null, userPhone=null, userLocation=null, userPassword=null, userGender=null
        formData.append("userName", name);
        formData.append("userSchoolNumber", number);
        formData.append("userPassword", password);
        formData.append("userGander", gender);
        this.registerUserTea(formData);
      }
    });
  };

  registerUserTea = async (formData) => {
    try {
      const res = await axios({
        url: baseURL + "/tearegister",
        method: "post",
        data: formData,
      });
      if (res && res.status === 200) {
        const { data } = res;
        console.log(res);
        if (data && data.code === 200) {
          // setTimeout(() => {
            setItem(KEYS.KEY_CUR_TAB_KEY,"2")
            message.success(data.data, 3,()=>{
              window.location = "/";
            });
            
          // }, 1000);
        } else {
          message.error(res.data.msg, 2);
        }
      } else {
        message.error(res.data.msg, 2);
      }
    } catch (error) {
      message.error("系统错误，刷新页面或者联系管理员！！", 2);
    }
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次输入的密码不一致!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "86",
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );

    const retrievebutton = getFieldDecorator(
      "retrieve",
      {}
    )(
      <Button type="primary">
        {this.state.number === 0 ? "获取验证码" : this.state.number}
      </Button>
    );

    return (
      <Form
        style={{
          width: "600px",
          position: "relative",
          margin: "50px auto 0 auto",
        }}
        onSubmit={this.handleSubmitTea}
        {...formItemLayout}
      >
        <Form.Item label="姓名">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "请输入你的名字",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="登录用户名">
          {getFieldDecorator("number", {
            rules: [
              {
                required: true,
                message: "请输入登录用户名",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="密码" hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "请输入你的密码,最少6位",
                max: 18,
                min: 6,
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="确认密码" hasFeedback>
          {getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "请再次输入密码,验证",
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item label="性别">
          {getFieldDecorator("gender", {
            rules: [{ required: true, message: "确认性别!" }],
          })(
            <Radio.Group onChange={this.onChange} value={this.state.value}>
              <Radio value={0}>男</Radio>
              <Radio value={1}>女</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        {/*}
                <Form.Item label="验证码">
                    {getFieldDecorator('retrieveCode', {
                        rules: [
                            {
                                required: true,
                                message: '请输入你的验证码',
                            },
                        ],
                    })(<Input addonAfter={retrievebutton}/>)}
                </Form.Item>

                {/*注册*/}
        <Form.Item wrapperCol={{ offset: 12 }}>
          <Button type="primary" htmlType="submit">
            注册
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const RegistrationForm = Form.create({ name: "register" })(RegistrationForm2);
export default RegistrationForm;
