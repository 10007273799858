export const setItemSession = (key, value) => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, value)
    }
}

export const getItemSession = (key) => {
    if (typeof window !== 'undefined') {
        return window.sessionStorage.getItem(key)
    }
}

export const removeItemSession = (key) => {
    if (typeof window !== 'undefined') {
        return window.sessionStorage.removeItem(key)
    }
}

export const KEYS_SESSION = {
    KEY_STA_STUID:'staticStudentId',
    KEY_STA_COURSEID:'staticCourseId',
    KEY_STA_DATA:'staticData',
    KEY_CHECK_DATA:'checkData',
    KEY_ALL_DATA:'allData'

}