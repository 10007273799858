import React, { Component } from 'react'
import { Layout, Tabs,Alert } from 'antd'

import LoginForm from '../../../components/LoginForm/LoginForm'
import BasicFooter from '../../../components/BasicFooter/BasicFooter'
import './login.scss'
import { getItem, KEYS, setItem,removeItem } from '../../../utils/localStorage'
const { Footer, Content } = Layout
const { TabPane } = Tabs

class Login extends Component {
    constructor(props){
        super(props)
        this.state={
            tel: '',
            password: '',
            tabKey:'1'
        }
    }

    TabChange = (key)=> {
        this.setState({
            tabKey:key
        },()=>{
            console.log(this.state.tabKey)
        })
        
    }

    componentDidMount=() =>{
        let tbKey = getItem(KEYS.KEY_CUR_TAB_KEY)
        console.log("首次渲染了")
        console.log(this.state.tabKey)
        if(tbKey){
            console.log("加载tabkey" + tbKey)
            this.setState({
                tabKey: tbKey
            },()=>{
                console.log(this.state.tabKey)
                removeItem(KEYS.KEY_CUR_TAB_KEY)
            })
        }
    }

    componentDidUpdate=() => {

    }

    render(){
        return (
            <Layout style={{
                minWidth:'350px',
                background:'url("https://pmath.club:8443/data/img/backgroundImage/images/homebg2.png") no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize:'100% auto'
                // backgroundSize: 'cover',
                // backgroundColor:'#005ca2'
            }}>
                
                <Content className='login-content'>
                    <div className='middle-center' style={{background:'#fff',padding:'50px',boxShadow:'0px 0px 20px #ccc',borderRadius:'20px'}}>
                        {/* <div style={{width:'100%',fontSize:'20px',fontWeight:'bold',textAlign:'justify'}}>离散数学在线学习系统</div> */}
                        {this.state.tabKey === '1' ?
                        <Tabs 
                            activeKey="1" 
                            onChange={this.TabChange} 
                            style={{width:'16rem'}}
                        >
                            <TabPane 
                                tab="学生登录" 
                                key="1" 
                            >
                                {/* <Alert
                                    type="warning"
                                    message="请未经过注册步骤的同学，登陆时选择学校为“无”。"
                                    style={{width:'15rem'}}
                                    banner
                                    closable
                                /> */}
                                <LoginForm 
                                    history={this.props.history}
                                    tabKey={this.state.tabKey}
                                />
                            </TabPane>
                            <TabPane 
                                tab="教师登录" 
                                key="2" 
                            >
                                <LoginForm 
                                    history={this.props.history}
                                    tabKey={this.state.tabKey}
                                />
                            </TabPane>
                        </Tabs>
                        :
                        <Tabs 
                            activeKey="2" 
                            onChange={this.TabChange} 
                            style={{width:'16rem'}}
                        >
                            <TabPane 
                                tab="学生登录" 
                                key="1" 
                            >
                                <LoginForm 
                                    history={this.props.history}
                                    tabKey={this.state.tabKey}
                                />
                            </TabPane>
                            <TabPane 
                                tab="教师登录" 
                                key="2" 
                            >
                                <LoginForm 
                                    history={this.props.history}
                                    tabKey={this.state.tabKey}
                                />
                            </TabPane>
                        </Tabs>
                        }
                        
                    </div>
                </Content>
                <Footer>
                    <BasicFooter/>
                </Footer>
            </Layout>
        )
    }
}

export default Login
