import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
    message,
    Table,
    Icon,
    ConfigProvider,
    Button,
    Form,
    Select,
    DatePicker,
    Alert
} from 'antd'
import './statisticalData.css'
import zhCN from 'antd/lib/locale-provider/zh_CN';
import BasicLayoutForTea from '../../../components/BasicLayoutForTea/BasicLayoutForTea'
import { getItem, KEYS,setItem } from '../../../utils/localStorage'
import { getItemSession, KEYS_SESSION,setItemSession } from '../../../utils/sessionStorage'
import axios from 'axios'
import moment from 'moment'
import { NormaltimeToTimestamp } from '../../../utils/timeFormat'
import {CSVLink} from "react-csv";
import {baseURL,baseIP}  from "../../../utils/application";

const {RangePicker}=DatePicker
const FormItem = Form.Item
const baseUrl=baseIP
const { Option } = Select
const classworkTypes = ['不限','课后作业','课堂作业']
const timeFormat="YYYY-MM-DD HH:mm:ss"
let pageSize=7
class studyAnalysis extends Component{
    constructor(props){
        super(props)
        this.state = {
            students: null,//所有参与了该课程的学生[{"studentId":,"studentName":,"courseId","courseName":},...]
            courses:[],//某老师的所有课程[{},...]
            classworks: null,//某老师所有课程的所有布置的作业[{},...]
            answers:null,//所有课程所有作业的所有答案[[{},...],[],...]

            //   curStudents:null,
            curCourse: null,
            curCourseName:null,
            curClassworks:null,
            //   curAnswers:null,

            courseFirst:false,
            curClws:[{"classworkId":-1,"classworkTitle":"不限"}],//名字不能变
            showClw:{"showAll":true,"showClwId":null},
            showClwType:"不限",

            //以下为级联展示所用
            curCha:{
                "classworkId":-1,
                "classworkTitle":"不限"
            },
            curClwType:"不限",
            curClwBT:null,
            curClwDT:null,


            numClw:0,
            numClass:0,
            numHome:0,
            numStu:0,

            //展示用蓝本
            showStuAns:null,
            //下载用蓝本
            curStuAns:null,
            //展示用
            showStuAnsChange:null,
            //下载用
            curStuAnsChange:[
                ['学号','姓名','课程名']
            ],


            currentPage: 1,
            totalPages: 0,
            totalEntries: 0,
            current: 1,

            showColumns:null,

            chapters:[]
        }
    }

    handlePageChange = (page) => {
        this.setState({
            currentPage: page
        })
    }

    componentDidMount = () => {
        // let data=getItemSession(KEYS_SESSION.KEY_STA_DATA)
        // if(data===undefined || data===null || data===''){

        // }else{
        //     data=JSON.parse(data)
        //     this.setState({
        //         students: data.students,
        //         answers:data.answers,

        //         curCourse: data.curCourse,
        //         curCourseName:data.curCourseName,
        //         curClassworks:data.curClassworks,

        //         courseFirst:data.courseFirst,
        //         curClws:data.curClws,
        //         showClw:data.showClw,
        //         showClwType:data.showClwType,

        //         curCha:data.curCha,
        //         curClwType:data.curClwType,
        //         curClwBT:data.curClwBT,
        //         curClwDT:data.curClwDT,

        //         numClw:data.numClw,
        //         numClass:data.numClass,
        //         numHome:data.numHome,
        //         numStu:data.numStu,

        //         //展示用蓝本
        //         showStuAns:data.showStuAns,
        //         //下载用蓝本
        //         curStuAns:data.curStuAns,
        //         //展示用
        //         showStuAnsChange:data.showStuAnsChange,
        //         //下载用
        //         curStuAnsChange:data.curStuAnsChange,

        //         currentPage: data.currentPage,
        //         totalPages: data.totalPages,
        //         totalEntries: data.totalEntries,
        //         current: data.current,

        //         showColumns:data.showColumns
        //     })
        //     this.setColumns(data.courseFirst,data.numStu,data.numClw,data.curClassworks,data.curStuAns,data.showStuAns,data.showClw,data.showClwType,data.curClwBT,data.curClwDT)
        // }
        this.loadAllClassworks()
        this.loadAllCourses()

    }
    // componentWillUnmount(){
    //     if(this.state.curCourse!==null){
    //         let string=JSON.stringify(this.state)
    //         setItemSession(KEYS_SESSION.KEY_STA_DATA,string)
    //         setItemSession(KEYS_SESSION.KEY_STA_COURSEID,this.state.curCourse)
    //     }
    // }
    loadAnswersByCourseStudent=async (courseId,studentId)=>{
        const recAnswers=await axios.get(baseURL+'/answer/answersForOne?studentId='+studentId+'&courseId='+courseId)
        let resAnswers=recAnswers.data
        if(resAnswers && resAnswers.code===200){
            if(resAnswers.data && resAnswers.data.length>0){
                let arrAnswers = []
                resAnswers.data.map((item,index) => {
                    arrAnswers = [...arrAnswers, item]
                    return null
                })
                return arrAnswers
            }
            else{
                message.info('获得该学生该课程回答失败',2)
                return null
            }
        }
        else{
            message.error('获取学生回答数据失败！！',2)
            return null
        }
    }
    loadStudentsByCourse=async (courseId)=>{
        const recStudents=await axios.get(baseURL+'/course/getCourseMember?courseId='+courseId)
        const resStudents=recStudents.data
        console.log(resStudents)
        if(resStudents && resStudents.code===200){
            if(resStudents.data && resStudents.data.length>0){
                let arrStudents = []
                resStudents.data.map((item,index) => {
                    arrStudents = [...arrStudents, item]
                    return null
                })
                this.setState({
                    students: [...arrStudents]
                })
                return arrStudents
            }
            else{
                message.info('此课程没有学生',2)
                return null
            }
        }
        else{
            message.error('获取学生数据失败！！',2)
            return null
        }
    }
    loadAllClassworks=async ()=>{
        const teacherId = JSON.parse(getItem(KEYS.KEY_CUR_USERINFO)).userId
        const resClassworks = await axios.get(baseURL+'/classwork/getByTeacherId?teacherId=' + teacherId)
        if (resClassworks && resClassworks.status === 200) {
            if (resClassworks.data && resClassworks.data.length > 0) {
                let arrClassworks = []
                resClassworks.data.map((item,index) => {
                    arrClassworks = [...arrClassworks, item]
                    return null
                })
                this.setState({
                    classworks: [...arrClassworks]
                })
            }else {
                message.info('此处没有作业',2)
            }
        }else{
            message.error('获取作业数据失败！！',2)
        }
    }
    loadAllCourses=async ()=>{
           const teacherId = JSON.parse(getItem(KEYS.KEY_CUR_USERINFO)).userId
           const resCourses = await axios.get(baseURL+'/course/getbyteacherId?courseTeacherId='+teacherId)
           if(resCourses && resCourses.status===200){
               if(resCourses.data && resCourses.data.length>0){
                   let arrCourses = []
                   resCourses.data.map((item,index) => {
                       arrCourses = [...arrCourses, item]
                       return null
                   })
                   let courses=[...arrCourses]
                   this.setState({
                       courses: courses
                   })
               }
               else{
                   message.info('您是否创建课程？？',2)
               }
           }
           else{
               message.error('获取课程数据失败！！',2)
           }
       }

    showInit=()=>{
        this.setState({
            showClw:{"showAll":true,"showClwId":null},
            showClwType:"不限"
        })
    }
    curInit=()=>{
        this.setState({
            curCha:{
                "classworkId":-1,
                "classworkTitle":"不限"
            },
            curClwType:"不限",
            curClwBT:null,
            curClwDT:null
        })
    }
    colInit=()=>{
        this.setState({
            numClw:0,
            numClass:0,
            numHome:0,
            numStu:0,

            showColumns:null
        })
    }
    stuAnsInit=()=>{
        this.setState({
            curStuAns:null,
            showStuAns:null
        })
    }
    stuAnsChangeInit=()=>{
        this.setState({
            curStuAnsChange:[
                ['学号','姓名','课程名']
            ],
            showStuAnsChange:null
        })
    }
    getPointByAnswer=(allClassworks,anAnswer)=>{
        if(allClassworks && anAnswer){
            let lenClw=allClassworks.length
            for(let numClw=0;numClw<lenClw;numClw++){
                if(allClassworks[numClw].classworkId===anAnswer.answerClassworkId){
                    if(allClassworks[numClw].classworkType==="课堂作业"){
                        return 1
                    }else{//课后作业
                        if(!anAnswer.answerFirstSubmittime){
                            return 0
                        }else if(anAnswer.answerFirstSubmittime && anAnswer.answerSecondSubmittime===null){
                            return 1
                        }else{//交了两次
                            return 2
                        }
                    }
                }
            }
            return 0
        }
        return 0
    }

    getClassworkByCourse=async (value)=>{
        const res = await axios({
            url:baseUrl+'/homeworkManager/classwork/chapterName?courseId='+value,
            method:'get'
        })
        if(res && res.status===200){
            if(res.data.data && res.data.data.length>0){
                let chapters=res.data.data
                this.setState({
                    chapters:chapters
                })
                let classworks=[]
                for(let i=0;i<chapters.length;i++){
                    let res2= await axios({
                        url:baseUrl+'/homeworkManager/classwork/TeacherIdAndChapterName?teacherId='+JSON.parse(getItem(KEYS.KEY_CUR_USERINFO)).userId+'&chapterName='+chapters[i],
                        method:'get'
                    })
                    classworks=[...classworks,...res2.data]
                }
            }else {
                message.error('该课程没有章节',2)
            }
        }else {
            message.error('请求课程章节失败',2)
        }
    }

    handleCourseNameSelect=async (value)=>{
        message.loading('加载中，请稍候',0)
        let flag=0
        for(let i=0;i<this.state.courses.length && flag===0;i++){
            if(this.state.courses[i].courseId===value){
                flag=1
                this.setState({
                    curCourseName:this.state.courses[i].courseName
                })
            }
        }
        this.getClassworkByCourse(value)
        this.setState({
            curCourse : value
        })
        this.showInit()
        this.curInit()
        this.colInit()
        this.stuAnsInit()
        this.stuAnsChangeInit()
        //从所有作业中获得该课程作业
        const{classworks}=this.state
        let resClassworks=[]
        let resHome=[]
        let numHome=0
        let resClass=[]
        let numClass=0
        let lenClassworks=classworks?classworks.length:0
        let numC=0
        for(numC=0;numC<lenClassworks;numC++){
            if(classworks[numC].classworkCourseId===value){
                if(classworks[numC].classworkType==="课堂作业"){
                    resClass=[...resClass,classworks[numC]]
                    numClass++
                }else {
                    resHome=[...resHome,classworks[numC]]
                    numHome++
                }
            }
        }
        resClassworks=[...resClass,...resHome]
        this.setState({
            numClw:numHome+numClass,
            numClass:numClass,
            numHome:numHome
        })

        if(resClassworks && resClassworks.length>0){
            this.setState({
                curClassworks:resClassworks
            })
        }else {
            this.setState({
                curClassworks:null
            })
        }
        //获得该课学生
        let resStudents=await this.loadStudentsByCourse(value)
        if(resStudents && resStudents.length>0){
            // console.log("students",resStudents)
            this.setState({
                students:resStudents,
                numStu:resStudents.length
            })
        }else {
            this.setState({
                students:null,
                numStu:0
            })
        }
        //在有作业和学生的情况下找答案
        let resCurSA=[]//[[],...]
        let resShowSA=[]//[{},...]
        this.setState({
            courseFirst:true
        })
        if(resClassworks && resStudents && resClassworks.length>0 && resStudents.length>0){
            //获得每个学生该课的答案[[{},...]...]
            let resAnswers=[]
            let lenS=resStudents.length
            for(let tmpnum=0;tmpnum<lenS;tmpnum++){
                let resLineAnswers=await this.loadAnswersByCourseStudent(value,resStudents[tmpnum].studentId)
                if(resLineAnswers && resLineAnswers.length>0){
                    resAnswers=[...resAnswers,resLineAnswers]
                }
            }

            //将答案转为蓝本
            let numStudents=0
            numC=0
            let numAnswers=0
            let lenStudents=resStudents.length
            lenClassworks=resClassworks.length
            let lenAnswers=resAnswers?resAnswers.length:0
            for(numStudents=0;numStudents<lenStudents;numStudents++){
                let stu=resStudents[numStudents]
                let lineResCur=[stu.userSchoolNumber,stu.studentName,stu.courseName]
                let lineResShow={}
                let strShow='"userSchoolNumber":"'+stu.userSchoolNumber+'"'+
                    ',"userId":"'+stu.studentId+'"'+
                    ',"studentName":"'+stu.studentName+'"'+
                    ',"courseId":'+stu.courseId+
                    ',"courseName":"'+stu.courseName+'"'
                for(numC=0;numC<lenClassworks;numC++){
                    let flagInAns=0
                    //去答案中找
                    for(numAnswers=0;numAnswers<lenAnswers;numAnswers++){
                        //该学生有答案，但不确定有没有该作业答案
                        if(resAnswers[numAnswers][0].answerStudentId===resStudents[numStudents].studentId){
                            let lenInAns=resAnswers[numAnswers].length
                            for(let inAns=0;inAns<lenInAns;inAns++){
                                //找到答案
                                if(resAnswers[numAnswers][inAns].answerClassworkId===resClassworks[numC].classworkId){
                                    let ansPoint=this.getPointByAnswer(resClassworks,resAnswers[numAnswers][inAns])
                                    lineResCur=[...lineResCur,ansPoint]
                                    strShow=strShow+',"point'+resClassworks[numC].classworkId+'":'+ansPoint
                                    flagInAns=1
                                }
                            }
                        }
                    }
                    //答案中没有
                    if(flagInAns===0){
                        lineResCur=[...lineResCur,0]
                        strShow=strShow+',"point'+resClassworks[numC].classworkId+'":'+0
                    }
                }
                strShow='{'+strShow+'}'
                console.log(strShow)
                lineResShow=JSON.parse(strShow)
                resCurSA=[...resCurSA,lineResCur]
                resShowSA=[...resShowSA,lineResShow]

            }
            this.setState({
                curStuAns:resCurSA,
                showStuAns:resShowSA
            })
        }
        let tmpClws=[
            {"classworkId":-1,"classworkTitle":"不限"},
            ...resClassworks
        ]
        this.setState({
            curClws:tmpClws
        })
        this.setColumns(true,resStudents?resStudents.length:0,numHome+numClass,resClassworks && resClassworks.length>0?resClassworks:null,resCurSA,resShowSA,{"showAll":true,"showClwId":null},"不限",null,null)
        message.destroy()
    }

    
    

    checkDetails(record){
        console.log(record)

        const { userId, courseId, studentName } = record; // 假设这些值在 record 中
        const userType = "student"
        
        // 构建 URL
        const url = `/#/analysisreportfortea?userId=${userId}&userType=${userType}&courseId=${courseId}&userName=${studentName}`;
        
        // 在新窗口打开
        window.open(url, '_blank');
        // this.props.history.push(url);

        // window.open('/#/analysisreportfortea', '_blank');

    }
    setColumns=async (courseFirst,numStu,numClw,curClassworks,curStuAns,showStuAns,showClw,showClwType,curClwBT,curClwDT)=>{
        if(courseFirst && numStu>0 && numClw>0){
            let columns = [
                {
                    title:"学生学号",
                    dataIndex:'userSchoolNumber',
                    key:'userSchoolNumber'
                },
                {
                    title:"学生姓名",
                    dataIndex:'studentName',
                    key:'studentName'
                },
                {
                    title:"课程名",
                    dataIndex:'courseName',
                    key:'courseName'
                },
                {
                    title:'操作',
                    dataIndex:'actions',
                    key:'actions',
                    render: (boxId,record) => {
                        return (
                            <Fragment>
                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={this.checkDetails.bind(this,record)}
                                >
                                    查看
                                </Button>
                            </Fragment>
                        )
                    }
                },
            ]
            
            let aviClw=curClassworks
            const aviStuAns=curStuAns
            let aviShowStuAns=showStuAns
            let resClw=[]
            let resStuAns=[]
            let showClwL=showClw
            let showClwTypeL=showClwType
            let curClwBTL=curClwBT
            let curClwDTL=curClwDT
            //下载用change的前三列
            if(aviStuAns!=null){
                let numSA=0
                let lenSA=aviStuAns.length
                for(numSA=0;numSA<lenSA;numSA++){
                    resStuAns=[...resStuAns,[aviStuAns[numSA][0],aviStuAns[numSA][1],aviStuAns[numSA][2]]]
                }
            }

            if(aviClw!=null){
                if(!showClwL.showAll){
                    let numC=0
                    let lenC=aviClw.length
                    if(lenC>0){
                        for(numC=0;numC<lenC;numC++){
                            if (aviClw[numC].classworkId===showClwL.showClwId){
                                resClw=[...resClw,aviClw[numC]]
                            }
                        }
                        aviClw=resClw
                    }
                }
                resClw=[]
                if(showClwTypeL!=="不限"){
                    let numC=0
                    let lenC=aviClw.length
                    if(lenC>0){
                        for(numC=0;numC<lenC;numC++){
                            if (aviClw[numC].classworkType===showClwTypeL){
                                resClw=[...resClw,aviClw[numC]]
                            }
                        }
                        aviClw=resClw
                    }
                }
                resClw=[]
                if(curClwBTL && curClwDTL){
                    let numC=0
                    let lenC=aviClw.length
                    let curBTStamp=NormaltimeToTimestamp(curClwBTL)
                    let curDTStamp=NormaltimeToTimestamp(curClwDTL)
                    if(lenC>0){
                        for(numC=0;numC<lenC;numC++){
                            let tmpStamp=NormaltimeToTimestamp(aviClw[numC].classworkBegintime)
                            if(tmpStamp>=curBTStamp && tmpStamp<=curDTStamp){
                                resClw=[...resClw,aviClw[numC]]
                            }
                        }
                        aviClw=resClw
                    }
                }
                resClw=[]

                let numTmp1=0,numTmp2=0
                let aviClwNums=[]
                let tmpClw=curClassworks
                //前者是当前查询条件下成立的作业数，后者是此课程作业数
                let lenTmp1=aviClw.length,lenTmp2=tmpClw.length
                for(numTmp1=0;numTmp1<lenTmp1;numTmp1++){
                    for(numTmp2=0;numTmp2<lenTmp2;numTmp2++){
                        if(aviClw[numTmp1].classworkId===tmpClw[numTmp2].classworkId){
                            aviClwNums=[...aviClwNums,numTmp2]
                        }
                    }
                }
                lenTmp1=aviStuAns.length
                lenTmp2=aviClwNums.length
                //对于每一个学生
                for(numTmp1=0;numTmp1<lenTmp1;numTmp1++){
                    let tmpline=[]
                    let subNum=0
                    //在当前查询条件下的每个作业
                    for(numTmp2=0;numTmp2<lenTmp2;numTmp2++){
                        tmpline=[...tmpline,aviStuAns[numTmp1][aviClwNums[numTmp2]+3]]
                        if(aviStuAns[numTmp1][aviClwNums[numTmp2]+3]!==0){
                            subNum++
                        }
                    }
                    if(lenTmp2>0){
                        resStuAns[numTmp1]=[...resStuAns[numTmp1],...tmpline,lenTmp2,subNum,lenTmp2-subNum]
                        aviShowStuAns[numTmp1]={...aviShowStuAns[numTmp1],"totalNum":lenTmp2,"submitNum":subNum,"notSubmitNum":lenTmp2-subNum}
                    }
                }

                let lineTitle=["学生ID","学生姓名","课程名"]
                let numC=0
                let lenC=aviClw.length
                if(lenC>0){
                    // for(numC=0;numC<lenC;numC++){
                    //     let tmpClwId=aviClw[numC].classworkId
                    //     let colTmp={
                    //         render:(text,record,stuNum)=>(
                    //                 <Button type="link" onClick={this.onPointClick.bind(this,stuNum,tmpClwId,record)}>{text}</Button>
                    //         )
                    //     }
                    //     lineTitle=[...lineTitle,aviClw[numC].classworkTitle]
                    //    /* let colstr='{"title":"'+aviClw[numC].classworkTitle+'"'+
                    //         ',"dataIndex":"point'+aviClw[numC].classworkId+'"'+
                    //         ',"key":"point'+aviClw[numC].classworkId+'"}'
                    //     let col={}
                    //     col=JSON.parse(colstr)*/
                    //     colTmp.title=aviClw[numC].classworkTitle
                    //     colTmp.dataIndex='point'+aviClw[numC].classworkId
                    //     colTmp.key='point'+aviClw[numC].classworkId
                    //     columns=[...columns,colTmp]

                    // }
                    lineTitle=[...lineTitle,"作业总数","提交作业数","未交作业数"]
                    let totalNum={
                        "title":"作业总数",
                        "dataIndex":"totalNum",
                        "key":"totalNum"
                    }
                    let submitNum={
                        "title":"提交作业数",
                        "dataIndex":"submitNum",
                        "key":"submitNum"
                    }
                    let notSubmitNum={
                        "title":"未交作业数",
                        "dataIndex":"notSubmitNum",
                        "key":"notSubmitNum"
                    }

                    columns=[...columns,totalNum,submitNum,notSubmitNum]
                }
                resStuAns=[lineTitle,...resStuAns]
                if(resStuAns===null){
                    resStuAns=[
                        ['学号','姓名','课程名']
                    ]
                }

                this.setState({
                    curStuAnsChange:resStuAns,
                    showStuAnsChange:aviShowStuAns,//数据源
                    showColumns:columns
                })
            }
        }
    }

    
    async onPointClick(stuNum,clwId,record){
        //let realNum=(this.state.currentPage-1)*pageSize+stuNum
        //let stu=this.state.students[realNum]
        let stu=record
        let classworkId=clwId


        const res=await axios({
            url:baseUrl+'/homeworkManager/answer/getByStudentAndClasswork?studentId='+stu.studentId+'&classworkId='+classworkId,
            method:'get'
        })
        if(res.status===200 && res.data && res.data!==''){
            for(let i=0;i<this.state.curClassworks.length;i++){
                if(classworkId===this.state.curClassworks[i].classworkId){
                    setItem(KEYS.KEY_CUR_CLASSWORK,JSON.stringify(this.state.curClassworks[i]))
                    setItem(KEYS.KEY_CUR_ANSWER,res.data.answerId)
                    setItem(KEYS.KEY_CUR_ANSWERS,'['+JSON.stringify(res.data)+']')
                    this.props.history.push('/correctResultSta')
                    return
                }
            }
        }else {
            message.error('请求学生作业情况失败',2)
        }
    }

    render() {

        return (
            <BasicLayoutForTea 
            title='学情分析' 
            describe='本页列出学生并且可选择导出学情分析报告'
            breadcrumb='学情分析'
            sidelist={()=>
              <ul id="sidelist">
                  <li>
                      <Link to='/statisticalData' >
                          <div></div>
                          <span>
                            学生作业情况统计
                          </span>
                      </Link>
                  </li>
                  <li className='selected'>
                      <Link to='/analysisreport' >
                          <div></div>
                          <span>
                            学情分析
                          </span>
                      </Link>
                  </li>
              </ul>
            }
            >
                {
                    this.state.courses && this.state.courses.length>0
                        ? <Fragment>
                            <Form layout='inline' style={{display:'flex',marginLeft:'2vw', marginBottom:'2vh'}}>
                                <FormItem label="课程名" >
                                    <Select
                                        onChange={this.handleCourseNameSelect}
                                        notFoundContent={'请确认您是否已经创建课程？？？'}
                                        placeholder={this.state.curCourseName?this.state.curCourseName:'请先选择课程'}
                                        style={{width:'12vw'}}
                                    >
                                        {
                                            this.state.courses.map((item,index) => {
                                                return <Option
                                                    key={item.courseId}
                                                    value={item.courseId}
                                                >
                                                    {item.courseName}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                                
                            </Form>


                            {this.state.courseFirst && this.state.numClw===0?<Alert message="抱歉，该课程还没有布置任何作业" type="warning" closable/>:null}
                            {this.state.courseFirst && this.state.numStu===0?<Alert message="抱歉，该课程还没有任何学生" type="warning" closable/>:null}
                            <ConfigProvider locale={zhCN}>
                                <Table
                                    columns={ this.state.showColumns }
                                    dataSource={ this.state.showStuAnsChange }
                                    rowKey={record => record.studentId }
                                    bordered
                                    pagination={{ //分页
                                        showQuickJumper: true,
                                        hideOnSinglePage:true,
                                        current: this.state.currentPage,
                                        showSizeChanger:true,
                                        pageSize: pageSize,
                                        total: this.state.totalEntries,
                                        onChange: this.handlePageChange
                                    }}
                                    style={{margin:'0 1.5rem'}}
                                />
                            </ConfigProvider>

                        </Fragment>
                        :this.state.courseFirst?<Alert message="抱歉，没有属于您的课程" type="warning" closable/>:null
                }
            </BasicLayoutForTea>
        );
    }

}




export default studyAnalysis