import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Radio,
  message,
} from "antd";
import axios from "axios";
import { getItem, KEYS,setItem } from "../../utils/localStorage";
import { baseURL } from "../../utils/application";
import FormItem from "antd/lib/form/FormItem";
const { Option } = Select;

class RegistrationForm1 extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    value: null,
    courses: null,
    number: 0,
    ChuserType: 'student',
    querySchool:'',//当前的课程所在的学校
    allSchools:["电子科技大学","其他学校"],//所有学校
    filteredSchools: [], // 搜索过滤后的学校名称列表
  };

  // 从后端获取到所有课程
  componentDidMount = () => {
    console.log("获取课程");
    // this.loadData();
    this.getAllSchInfo();
  };
  loadData = async () => {
    const res = await axios.get(baseURL + "/course/getThisYearCourseAll");
    if (res && res.status === 200) {
      if (res.data.msg === "ok") {
        this.setState({
          courses: res.data.data,
        });
      } else {
        message.info("今年还没有正式开课!!!", 2);
      }
    } else {
      message.error(`${res.data.msg}`, 2);
    }
  };

  handleSubmit = async (e) => {
    console.log("进入学生注册")
    e.preventDefault();
    let formData = new FormData();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name, number, password, source ,gender,schoolPrefix} = values;

        // 强制将 schoolPrefix 转换为字符串
      let cleanedSchoolPrefix = String(schoolPrefix || '');
      if (cleanedSchoolPrefix.includes('undefined')) {
        // 过滤掉 schoolPrefix 中的 "undefined" 子字符串
        cleanedSchoolPrefix = cleanedSchoolPrefix.replace(/undefined/g, '').trim();
      }
        console.log(cleanedSchoolPrefix+number)
        //userSchoolNumber=null, userName=null, userType=null, userAuth=null, userPhone=null, userLocation=null, userPassword=null, userGander=null
        formData.append("userName", name);
        formData.append("userSchoolNumber", cleanedSchoolPrefix+number);
        formData.append("userPassword", password);
        formData.append("sourseId",source);
        formData.append('userGander',gender);
        this.registerUser(formData);
      }
    });
  };

  registerUser = async (formData) => {
    try {
      const res = await axios({
        url: baseURL + "/register",
        method: "post",
        data: formData,
      });
      console.log(res)
      if (res && res.status === 200) {
        const { data } = res;
        console.log(data);
        if (data && data.code === 200) {
          message.success(data.data, 2,()=>{
            window.location = "/";
          });
          
        } else {
          message.error(res.data.msg, 2);
        }
      } else {
        message.error(res.data.msg, 2);
      }
    } catch (error) {
      message.error("系统错误，刷新页面或者联系管理员！！", 2);
    }
  };

  handleSubmitTea = async (e) => {
    console.log("进入教师注册")
    e.preventDefault();
    let formData = new FormData();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name, password, gender, number,schoolname } = values;
        //userSchoolNumber=null, userName=null, userType=null, userAuth=null, userPhone=null, userLocation=null, userPassword=null, userGender=null
        formData.append("userName", name);
        formData.append("userSchoolNumber", number);
        formData.append("userPassword", password);
        formData.append("userGander", gender);
        formData.append("userSchoolName", schoolname);
        this.registerUserTea(formData);
      }
    });
  };

  registerUserTea = async (formData) => {
    try {
      const res = await axios({
        url: baseURL + "/tearegister",
        method: "post",
        data: formData,
      });
      if (res && res.status === 200) {
        const { data } = res;
        console.log(res);
        if (data && data.code === 200) {
          // setTimeout(() => {
            setItem(KEYS.KEY_CUR_TAB_KEY,"2")
            message.success(data.data, 3,()=>{
              window.location = "/";
            });
            
          // }, 1000);
        } else {
          message.error(res.data.msg, 2);
        }
      } else {
        message.error(res.data.msg, 2);
      }
    } catch (error) {
      message.error("系统错误，刷新页面或者联系管理员！！", 2);
    }
  };
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次输入的密码不一致!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  chooseUserType=(event)=>{
    console.log(event.target.value)
    this.setState({
      ChuserType: event.target.value
    })
  }

  queryCourseSchool=()=>{
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { source } = values;
        //userSchoolNumber=null, userName=null, userType=null, userAuth=null, userPhone=null, userLocation=null, userPassword=null, userGender=null
        this.queryTheCourseTea(source)
      }
    });
  }
  queryTheCourseTea=async(source)=>{
    console.log("得到的source是"+ source)
    let courseid = Number(source.split("-")[1]);
    console.log(courseid)
    const res=await axios.get(baseURL+'/course/getUserInfoSchool?Id='+courseid)
        const resSchool=res.data
        
        if(resSchool && resSchool.code===200){
            if(resSchool.data){
              console.log(resSchool.data);
              this.setState({
                querySchool:resSchool.data
              })
            }
            else{
                // message.info('此课程没有学生',2)
                return null
            }
        }
        else{
            message.error('查询课程学校失败',2)
            return null
        }
  }
  getAllSchInfo=async()=>{
    const res=await axios.get(baseURL+'/course/getAllSchools')
        const resSchools=res.data
        console.log(resSchools)
        if(resSchools && resSchools.code===200){
            if(resSchools.data){
              console.log(resSchools.data);
              this.setState({
                allSchools:resSchools.data,
                filteredSchools:resSchools.data
              })
            }
            else{
                // message.info('此课程没有学生',2)
                return null
            }
        }
        else{
            message.error('获取学校列表失败',2)
            return null
        }
  }

  // 处理学校名称的实时搜索
  handleSchoolSearch = (value) => {
    const inputValue = value.trim().toLowerCase();
    const filteredSchools = this.state.allSchools.filter((schoolName) =>
      schoolName.toLowerCase().includes(inputValue) // 根据拼音或汉字匹配
    );
    this.setState({ filteredSchools: filteredSchools });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { courses,ChuserType } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const selectBefore = getFieldDecorator("schoolPrefix", {
      initialValue: undefined,
    })(
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="选择学校"
        optionFilterProp="children"
        onSearch={this.handleSchoolSearch} // 处理搜索的输入
        filterOption={false} // 关闭内置的过滤功能，使用自定义的过滤逻辑
      >
        {this.state.filteredSchools.map((schoolName, index) => (
          <Option key={index} value={schoolName}>
            {schoolName}
          </Option>
        ))}
      </Select>
    )

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "86",
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );

    const retrievebutton = getFieldDecorator(
      "retrieve",
      {}
    )(
      <Button type="primary">
        {this.state.number === 0 ? "获取验证码" : this.state.number}
      </Button>
    );

    return (
      <>
        {/* <Form><FormItem>
        <Radio.Group
          defaultValue="student"
          buttonStyle="solid"
          onChange={this.chooseUserType}
        >
          <Radio.Button value="student">学生</Radio.Button>
          <Radio.Button value="teacher">教师</Radio.Button>
        </Radio.Group>
        </FormItem> </Form> */}
        <>
          <Form
            style={{
              width: "600px",
              position: "relative",
              margin: "50px auto 0 auto",
            }}
            onSubmit={ChuserType=== 'student'? this.handleSubmit : this.handleSubmitTea}
            {...formItemLayout}
          >
            <Form.Item label="注册身份">
              <Radio.Group
                defaultValue="student"
                buttonStyle="solid"
                onChange={this.chooseUserType}
              >
                <Radio.Button value="student">学生</Radio.Button>
                <Radio.Button value="teacher">教师</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {ChuserType === "student" ? (
              <>
                <Form.Item label="名字">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "请输入你的名字",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="学号">
                {/* <span>{this.state.querySchool}</span> */}
                  {getFieldDecorator("number", {
                    rules: [
                      {
                        required: true,
                        message: "请输入学号",
                      },
                    ],
                  })(<Input addonBefore={selectBefore}/>)}
                  {/* (<Input addonBefore={selectBefore}/>) */}
                </Form.Item>

              </>
            ) : (
              <>
                <Form.Item label="姓名">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "请输入你的名字",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="学校名称(全称)">
                  {getFieldDecorator("schoolname", {
                    rules: [
                      {
                        required: true,
                        message: "请输入学校全称",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="登录用户名">
                  {getFieldDecorator("number", {
                    rules: [
                      {
                        required: true,
                        message: "请输入登录用户名",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </>
            )}

            <Form.Item label="密码" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "请输入你的密码,最少6位",
                    max: 18,
                    min: 6,
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="确认密码" hasFeedback>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "请再次输入密码,验证",
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </Form.Item>
            <Form.Item label="性别">
              {getFieldDecorator("gender", {
                rules: [{ required: true, message: "确认性别!" }],
              })(
                <Radio.Group onChange={this.onChange} value={this.state.value}>
                  <Radio value={0}>男</Radio>
                  <Radio value={1}>女</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {ChuserType === 'student' ? 
              <>
                <Form.Item label="绑定课程">
                {getFieldDecorator("source", {
                  rules: [
                    {
                      required: true,
                      message: "请输入课程号！",
                      // max: 12,
                      // min: 6,
                    },
                  ],
                })(<Input placeholder="请输入课程号绑定课程" />)}
                <Button type="dashed" onClick={this.queryCourseSchool}> 查询 </Button> <span>{this.state.querySchool}</span>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 12 }}>
                <Button type="primary" htmlType="submit">
                  注册
                </Button>
              </Form.Item>
              </>
              : 
              <>
                
                <Form.Item wrapperCol={{ offset: 12 }}>
                  <Button type="primary" htmlType="submit">
                    注册
                  </Button>
                </Form.Item>
              </>
            }
            
          </Form>
        </>
        
      </>
    );
  }
}

const RegistrationForm = Form.create({ name: "register" })(RegistrationForm1);
export default RegistrationForm;
