import React, { Component } from 'react'
import './BasicFooter.css'

class BasicFooter extends Component {
    render() {
        return (
            <div className='basic-footer-body'>
                <div className='basic-footer-item'>
                    <div className='a-box'><a href='http://www.ss.uestc.edu.cn/' target='_blank' rel='noopener noreferrer'>信息与软件工程</a></div>
                    <div className='a-box'><a href='#' target='_blank' rel='noopener noreferrer'>帮助</a></div>
                </div>  
                {/* <div className='basic-footer-item a-box'>本网站只属于个人学习交流，未进行商业用途</div>    */}
                <div className='basic-footer-item a-box'>
                    <a href='https://beian.miit.gov.cn/'>蜀ICP备2024100553号-1</a> 
                </div>          
            </div>
        )
    }
}

export default BasicFooter