import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import CustomRoute from "./custom";
import loadable from "../utils/loadable";

import Login from "../pages/public/login/login";
import register from "../pages/public/register/register";
import tearegister from "../pages/public/register/teacherRegister";
import studyAnalysis from "../pages/teacher/statisticalData/studyAnalysis";
/*import Home from '../pages/public/home/home'
import MineCourse from '../pages/public/mine/mineCourse'
import Chapters from '../pages/public/chapters/chapters'
import PassModify from '../pages/public/passModify/passModify'
import ClwsStu from '../pages/public/clwsStu/clwsStu'

import Seatwork from '../pages/student/manageTask/seatwork/seatwork'
import Homework from '../pages/student/manageTask/homework/homework'
import CheckTask from '../pages/student/manageTask/check/check'
import UploadResult from '../pages/student/manageTask/upload/upload'
import CheckReview from '../pages/student/manageTask/check/review'
import Evaluate from '../pages/student/manageChapter/evaluate/evaluate'
import AutoKeyboard from '../pages/student/keyboard/keyboard'
import CheckSource from '../pages/student/manageSource/check/check'
import StudentAssistant from '../pages/student/studentAssistant/studentAssistant'
import TestDemo from '../pages/student/testDemo/testDemo'

import AddTask from '../pages/teacher/addTask/addTask'
import TaskTable from '../pages/teacher/checkTask/checkTask'
import AllTasks from '../pages/teacher/manageTask/manage'
import ResultTable from '../pages/teacher/correctTask/correct'
import CorrectResult from '../pages/teacher/correctResult/correctResult'
import CorrectResultSta from '../pages/teacher/correctResult/correctResultSta'
import AddCourse from '../pages/teacher/courseManage/addCourse/addCourse'
import CheckChapters from '../pages/teacher/manageChapter/check/check'
import CheckAnswer from '../pages/teacher/manageChapter/check/checkAnswer'
import UploadSource from '../pages/teacher/manageSource/upload/upload'
import StatisticalData from '../pages/teacher/statisticalData/statisticalData'
import TeacherAssistant from '../pages/teacher/teacherAssistant/teacherAssistant'

import ExchangeTable from "../pages/points/exchangeTable"
import OrdersTable from "../pages/points/ordersTable"
import PointsRule from "../pages/points/pointsRule"*/

const Home = loadable(() => import("../pages/public/home/home"));
const HomePage = loadable(() => import("../pages/public/homePage/homePage"));
const MineCourse = loadable(() => import("../pages/public/mine/mineCourse"));
const CourseAdd = loadable(() => import("../pages/public/mine/courseAdd"));
const Chapters = loadable(() => import("../pages/public/chapters/chapters"));
const PassModify = loadable(() =>
  import("../pages/public/passModify/passModify")
);
const ClwsStu = loadable(() => import("../pages/public/clwsStu/clwsStu"));

const Seatwork = loadable(() =>
  import("../pages/student/manageTask/seatwork/seatwork")
);
const Homework = loadable(() =>
  import("../pages/student/manageTask/homework/homework")
);
const CheckTask = loadable(() =>
  import("../pages/student/manageTask/check/check")
);
const UploadResult = loadable(() =>
  import("../pages/student/manageTask/upload/upload")
);
const CheckReview = loadable(() =>
  import("../pages/student/manageTask/check/review")
);
const Evaluate = loadable(() =>
  import("../pages/student/manageChapter/evaluate/evaluate")
);
const AutoKeyboard = loadable(() =>
  import("../pages/student/keyboard/keyboard")
);
const CheckSource = loadable(() =>
  import("../pages/student/manageSource/check/check")
);
const StudentAssistant = loadable(() =>
  import("../pages/student/studentAssistant/studentAssistant")
);
const TestDemo = loadable(() => import("../pages/student/testDemo/testDemo"));
//liuy修改
const testvue = loadable(() => import("../pages/student/testDemo/testvue"));

// 自主练习 new
const practice = loadable(() => import("../pages/student/practice/index"));
// 自主练习 - 题目 - 列表
const practiceList = loadable(() =>
  import("../pages/student/practice/practice-list")
);
// 自主练习 - 题目 - 详情
const practiceDetails = loadable(() =>
  import("../pages/student/practice/practice-details")
);
const atlas = loadable(() => import("../pages/student/atlas/atlas"));
// const atlas =loadsble(()=> import ("../pages/student/atlas/atlas"));
// 知识图谱

const organizePaper = loadable(() =>
  import("../pages/teacher/organizePaper/organizePaper")
);
const chapterhomeworkStu = loadable(() =>
  import("../components/chapterHomeworkForStu/chapterHomeworkForStu")
);
const chapterhomeworkTea = loadable(() =>
  import("../components/chapterHomeworkForTea/chapterHomeworkForTea")
);

const AddTask = loadable(() => import("../pages/teacher/addTask/addTask"));
const TaskTable = loadable(() =>
  import("../pages/teacher/checkTask/checkTask")
);
const AllTasks = loadable(() => import("../pages/teacher/manageTask/manage"));
const ResultTable = loadable(() =>
  import("../pages/teacher/correctTask/correct")
);
const CorrectResult = loadable(() =>
  import("../pages/teacher/correctResult/correctResult")
);
const CorrectResultSta = loadable(() =>
  import("../pages/teacher/correctResult/correctResultSta")
);
const AddCourse = loadable(() =>
  import("../pages/teacher/courseManage/addCourse/addCourse")
);
const CheckChapters = loadable(() =>
  import("../pages/teacher/manageChapter/check/check")
);
const CheckAnswer = loadable(() =>
  import("../pages/teacher/manageChapter/check/checkAnswer")
);
const UploadSource = loadable(() =>
  import("../pages/teacher/manageSource/upload/upload")
);
const TeacherCheckSource = loadable(() =>
  import("../pages/teacher/manageSource/check/check")
);
const StatisticalData = loadable(() =>
  import("../pages/teacher/statisticalData/statisticalData")
);
const TeacherAssistant = loadable(() =>
  import("../pages/teacher/teacherAssistant/teacherAssistant")
);
const HomeworkText = loadable(() =>
  import("../pages/teacher/homeworkText/homeworkText")
);
const AnalysisReport = loadable(() =>
  import("../pages/student/analysisReport/analysisReport")
);

const AnalysisReportforTea = loadable(() =>
  import("../pages/teacher/statisticalData/analysisReportforTea")
);

const Forum = loadable(() => import("../pages/public/forum/forum"));

const AddDiscuss = loadable(() => import("../pages/public/forum/addDiscuss"));

const DiscussDetail = loadable(() =>
  import("../pages/public/forum/discussDetail")
);

const DiscussManage = loadable(() =>
  import("../pages/public/forum/discussManage")
);

const DiscussRank = loadable(() => 
  import("../pages/public/forum/discussRank")
);

const DiscussMessage = loadable(() => 
  import("../pages/public/forum/discussMessage")
);

const ExchangeTable = loadable(() => import("../pages/points/exchangeTable"));
const OrdersTable = loadable(() => import("../pages/points/ordersTable"));
const PointsRule = loadable(() => import("../pages/points/pointsRule"));

// 课堂小测试
const TeaClassTest = loadable(() => import("../pages/teacher/classtestForTea/classtestForTea"));

// const PdfPreviewPage = loadable(() => import("../components/ResourcePreview/PDFPreview"));

const intelligent = loadable(() =>
  import("../pages/teacher/organizePaper/intelligent")
);

const StudyAnalysis = loadable(() =>
  import("../pages/teacher/statisticalData/studyAnalysis")
);

class MyRoute extends Component {
  render() {
    return (
      <Router>
        <Switch>
        {/* <Route path="/pdf-preview" component={PdfPreviewPage} /> */}
          <Route exact path="/autoKeyboard" component={AutoKeyboard} />
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={register} />
          <Route exact path="/tearegister" component={tearegister} />
          <CustomRoute path="/mineCourse" component={MineCourse} />
          <CustomRoute path="/courseAdd" component={CourseAdd} />
          <CustomRoute path="/home" component={Home} />
          <CustomRoute path="/homePage" component={HomePage} />
          <CustomRoute path="/chapters/:type" component={Chapters} />
          <CustomRoute path="/passwordModify" component={PassModify} />

          <CustomRoute path="/seatwork" component={Seatwork} />
          <CustomRoute path="/checkTask" component={CheckTask} />
          <CustomRoute path="/upload" component={UploadResult} />
          <CustomRoute path="/homework" component={Homework} />
          <CustomRoute path="/checkReview" component={CheckReview} />
          <Route path="/evaluate" component={Evaluate} />
          <CustomRoute path="/checkSource" component={CheckSource} />

          <CustomRoute path="/studentAssistant" component={StudentAssistant} />
          <CustomRoute path="/testDemo" component={TestDemo} />
          <CustomRoute path="/testvue" component={testvue} />
          {/* 自主练习 new */}
          <CustomRoute path="/practice" component={practice} />
          {/* 自主练习 - 题目 - 列表  */}
          <CustomRoute path="/practice-list" component={practiceList} />
          {/* 自主练习 - 题目 - 详情 */}
          <CustomRoute path="/practice-details" component={practiceDetails} />

          <CustomRoute path="/atlas" component={atlas} />
          {/* 知识图谱*/}

          <CustomRoute path="/addTask" component={AddTask} />
          <CustomRoute path="/allClassworks" component={AllTasks} />
          <CustomRoute path="/taskTable" component={TaskTable} />
          <CustomRoute path="/resultTable" component={ResultTable} />
          <CustomRoute path="/correctResult" component={CorrectResult} />
          <CustomRoute path="/addCourse" component={AddCourse} />
          <CustomRoute path="/checkChapters" component={CheckChapters} />
          <CustomRoute path="/checkAnswer" component={CheckAnswer} />
          <CustomRoute path="/uploadSource" component={UploadSource} />
          <CustomRoute
            path="/TeacherCheckSource"
            component={TeacherCheckSource}
          />
          <CustomRoute path="/homeworkText" component={HomeworkText} />

          <CustomRoute path="/statisticalData" component={StatisticalData} />
          <CustomRoute path="/teacherAssistant" component={TeacherAssistant} />

          <CustomRoute path="/clwsStu" component={ClwsStu} />
          <CustomRoute path="/correctResultSta" component={CorrectResultSta} />

          <CustomRoute path="/exchangeTable" component={ExchangeTable} />
          <CustomRoute path="/ordersTable" component={OrdersTable} />
          <CustomRoute path="/pointsRule" component={PointsRule} />
          <CustomRoute path="/organizepaper" component={organizePaper} />
          <CustomRoute
            path="/stuChapterhomework"
            component={chapterhomeworkStu}
          />
          <CustomRoute
            path="/teaChapterhomework"
            component={chapterhomeworkTea}
          />
          <CustomRoute path="/analysisreport" component={AnalysisReport} />

          <CustomRoute path="/analysisreportfortea" component={AnalysisReportforTea} />

          <CustomRoute path="/forum" component={Forum} />
          <CustomRoute path="/addDiscuss" component={AddDiscuss} />
          <CustomRoute path="/discussDetail" component={DiscussDetail} />

          {/* 课堂小测试 */}
          <CustomRoute path="/teaClassTest" component={TeaClassTest} />
          <CustomRoute path="/discussManage" component={DiscussManage} />
          <CustomRoute path="/discussRank" component={DiscussRank} />
          <CustomRoute path="/discussMessage" component={DiscussMessage} />

          <CustomRoute path="/intelligent" component={intelligent} />

          <CustomRoute path="/studyAnalysis" component={StudyAnalysis} />
        </Switch>
      </Router>
    );
  }
}

export default MyRoute;
